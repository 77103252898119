<template>
  <div class="animated fadeIn">
    <add-person :label="'کارمند پذیرش'" :job="'reception'"></add-person>
  </div>
</template>
<script>
const addPerson = () => import("../../components/hr/AddPerson.vue");

export default {
  components: {
    addPerson
  },
};
</script>